var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "da" },
    [
      _c(
        "van-form",
        { attrs: { "validate-first": "" }, on: { failed: _vm.onFailed } },
        [
          _c("van-field", {
            attrs: {
              label: "收货人",
              formatter: _vm.formatter,
              rules: _vm.nameRul,
              clearable: "",
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c("van-field", {
            attrs: {
              type: "phone",
              label: "手机号码",
              rules: _vm.mobilePhoneRul,
              clearable: "",
            },
            model: {
              value: _vm.mobilePhone,
              callback: function ($$v) {
                _vm.mobilePhone = $$v
              },
              expression: "mobilePhone",
            },
          }),
          _c("van-field", {
            attrs: {
              readonly: "",
              clickable: "",
              name: "area",
              value: _vm.addressOp,
              label: "所在地区",
              placeholder: "点击选择省市区",
            },
            on: {
              click: function ($event) {
                _vm.showArea = true
              },
            },
          }),
          _c("van-field", {
            attrs: {
              label: "详细地址",
              formatter: _vm.formatter,
              rules: _vm.addressRul,
              clearable: "",
            },
            model: {
              value: _vm.address,
              callback: function ($$v) {
                _vm.address = $$v
              },
              expression: "address",
            },
          }),
          _c("van-field", {
            attrs: { name: "switch", label: "设置默认地址" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function () {
                  return [
                    _c("van-switch", {
                      staticStyle: { position: "absolute", right: "0" },
                      attrs: { size: "30" },
                      model: {
                        value: _vm.isDefault,
                        callback: function ($$v) {
                          _vm.isDefault = $$v
                        },
                        expression: "isDefault",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("span", { staticClass: "tips" }, [
            _vm._v("提醒：每次下单会默认推荐使用该地址"),
          ]),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.showArea,
                callback: function ($$v) {
                  _vm.showArea = $$v
                },
                expression: "showArea",
              },
            },
            [
              _c("van-area", {
                attrs: { "area-list": _vm.areaList },
                on: {
                  confirm: _vm.onConfirm,
                  cancel: function ($event) {
                    _vm.showArea = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btns flex" },
            [
              _vm.id && !_vm.isDefault
                ? _c(
                    "van-button",
                    {
                      staticClass: "btn btn-del",
                      on: { click: _vm.deleteAddr },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _c(
                "van-button",
                { staticClass: "btn btn-save", on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
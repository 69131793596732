<template>
	<div class="da">
		<van-form validate-first @failed="onFailed">
			<!-- 输入任意文本 -->
			<van-field v-model="name" label="收货人" :formatter="formatter" :rules="nameRul" clearable/>
			<!-- 输入手机号，调起手机号键盘 -->
			<van-field v-model="mobilePhone" type="phone" label="手机号码" :rules="mobilePhoneRul" clearable/>
			<van-field readonly clickable name="area" :value="addressOp" label="所在地区" placeholder="点击选择省市区" @click="showArea = true" />
			<van-field v-model="address" label="详细地址" :formatter="formatter" :rules="addressRul" clearable/>
			<van-field name="switch" label="设置默认地址">
				<template #input>
					<van-switch v-model="isDefault" size="30" style="position: absolute;right: 0;" />
				</template>
			</van-field>
			<span class="tips">提醒：每次下单会默认推荐使用该地址</span>
			<van-popup v-model="showArea" position="bottom">
				<van-area
					:area-list="areaList"
					@confirm="onConfirm"
					@cancel="showArea = false"
				/>
			</van-popup>
			<div class="btns flex">
				<van-button class="btn btn-del" v-if="id && !isDefault" @click="deleteAddr">删除</van-button>
				<van-button class="btn btn-save" @click="save">保存</van-button>
			</div>
		</van-form>
	</div>
</template>
<script>
import areas from '@/lab/area';
import { deliOpt, deliDetail, deliDelete } from '@/request/api/user';

const mobilePhoneValidator = function (value) {
	return /^1[3|4|5|7|8|9][0-9]{9}$/.test(value);
};

export default {
	name: 'DeliveryDddr',
	data () {
		return {
			id: '',
			areaList: areas,
			name: '',
			mobilePhone: '',
			provence: {},
			city: {},
			county: {},
			addressOp: '',
			address: '',
			digit: null,
			nameRul: [
				{ required: true, message: '请填写收货人' }
			],
			mobilePhoneRul: [
				{ required: true, validator: mobilePhoneValidator, message: '请填写正确的手机号' }
			],
			addressOpRul: [
				{ required: true, message: '请填写所在地区' }
			],
			addressRul: [
				{ required: true, message: '请填写详细地址' }
			],
			showArea: false,
			isDefault: false,
			title: ''
		};
	},
	created () {
		const id = this.$route.query.id;
		if (id) {
			this.id = Number(id);
			document.title = '修改收货地址';
			this.getDetail();
		} else {
			document.title = '新建收货地址';
		}
	},
	methods: {
		getDetail () {
			deliDetail({ id: this.id }).then(data => {
				if (data && data === 'retry') {
					this.getDetail();
				} else if (data) {
					this.name = data.name;
					this.mobilePhone = data.mobilePhone;
					this.isDefault = data.isDefault;
					this.address = data.address;
					this.provence = { name: data.provenceName, code: data.provenceId.toString() };
					this.city = { name: data.cityName, code: data.cityId.toString() };
					this.county = { name: data.countyName, code: data.countyId.toString() };
					this.addressOp = `${data.provenceName}/${data.cityName}/${data.countyName}`;
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		onFailed (errorInfo) {
			console.log('failed---', errorInfo);
		},
		formatter (value) {
		// 过滤输入的数字
			return value.replace(/\s/g, '');
		},
		onConfirm (values) {
			this.provence = values[0];
			this.city = values[1];
			this.county = values[2];
			this.addressOp = values
				.filter((item) => !!item)
				.map((item) => item.name)
				.join('/');
			this.showArea = false;
		},
		save () {
			deliOpt({
				id: this.id,
				name: this.name,
				mobilePhone: this.mobilePhone,
				isDefault: this.isDefault,
				address: this.address,
				provenceId: this.provence.code,
				provenceName: this.provence.name,
				cityId: this.city.code,
				cityName: this.city.name,
				countyId: this.county.code,
				countyName: this.county.name
			}).then(data => {
				if (data && data === 'retry') {
					this.save();
				} else if (data) {
					this.$root.elMsg(this.id ? '修改成功' : '新建地址成功', 'success', () => {
						this.$root.go(-1);
					}, 1000);
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		deleteAddr () {
			deliDelete({ id: this.id }).then(data => {
				if (data && data === 'retry') {
					this.deleteAddr();
				} else if (data) {
					this.$root.elMsg('删除成功', 'success', () => {
						this.$root.go(-1);
					}, 1000);
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.da {
		background: white;
		color: #8B8B8B;
		padding-bottom: 20px;
		.tips {
			margin-left: 15.5px;
			position: absolute;
			bottom: 10px;
			font-size: 12px;
		}
	}
	/deep/ .van-field__label {
		color: #8B8B8B;
	}
	/deep/ .van-cell::after {
		border-bottom: none;
	}
	.btns {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: .12px .24px;
		box-sizing: border-box;
		background-color: #FFFFFF;
		.btn {
			height: 40px;
			border-radius: 21px;
			color: #FFFFFF;
			font-size: 15px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #FFFFFF;
			line-height: 40px;
			text-align: center;
			margin: 6px 12px;
		}
		.btn-del {
			background: #FFFFFF;
			border: 1px solid #FF6450;
			color: #FF6450;
			width: 108px;
			margin-right: 4px;
		}
		.btn-save {
			// width: 235px;
			flex: 1;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
		}
	}
</style>